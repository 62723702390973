<template>
    <div id="app">
      <div class="modal" v-if="showModal">
        <div class="modal-content">
          <div class="close-btn" @click="close">&times;</div>
          <div class="modal-body">
            <!-- <img :src="require('@/assets/images/etnex_popup/asset.png')" class="asset"> -->
            <h2 class="h2_text pb-4">{{ $t("amount_null") }}</h2>
            
            <p class="h2_text" style="font-size: 24px; padding-top: 0px;">{{ this.name }}</p>

            <div class="input-container">
                <p class="p_text">{{ $t("amount") }}: {{ $t("min") }} {{ this.minEtnex }} ETNEX</p>
                <p class="p_text">{{ $t("current") }}: {{ this.point3 + point2 }}  ETNEX</p>
                <label for="hashInput" class="input-label">{{ $t("amount") }}</label>
                <input id="amount" type="number" class="hash-input" placeholder=''/>
            </div>

          </div>
          <div class="modal-footer">
            <button @click="done_btn" class="done_button">{{ $t("next") }}</button>
          </div>
        </div>
      </div>
      <Dialog ref="msg"></Dialog>
    </div>
  </template>
  
  <script>
  import { getMemberInfo } from "../../../system/api/api";
  import { handleError } from "../../../system/handleRes";
  import Dialog from "../../../components/dialog.vue";
  export default {
    components: {
      Dialog,
    },
    props: {
      name: String,
      minEtnex: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        showModal: true,
        point2: "0",
        point3: "0",
      };
    },
    mounted() {
      this.loadItem();
    },
      methods: {
        close() {
          this.showModal = false;
        },

        loadItem(){
          var result = getMemberInfo();
          var self = this;
          result
            .then(function (value) {
              self.point2 = parseFloat(value.data.data.point2);
              self.point3 = parseFloat(value.data.data.point3);
              // console.log(self.bala);
            })
            .catch(function (error) {
              self.isLoading = false;
              self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
            });
        },
      done_btn() {

        var amount = document.getElementById("amount").value;
        // console.log("keyin:" + amount);
        // console.log("min:" + this.minEtnex);
        if (parseFloat(amount) < this.minEtnex) {
          this.$refs.msg.makeToast("danger", this.$t("min_etnex_error"));
          return; 
        } else if(!amount){
          this.$refs.msg.makeToast("danger", this.$t("amount_null"));
          return;
        } else if(amount % 100 !== 0){
          this.$refs.msg.makeToast("danger", this.$t("amount_not_multiple_of_100"));
          return; 
        }
        
        this.$emit('confirm-amount', amount);
      }
    },
  };
  </script>
  
  <style>

    .p_text {
        
        color: var(--Main, #FFF);
        text-align: center;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;

    }
    
    .h2_text {

        color: var(--Main, #FFF);
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;

    }

    .modal-body img{
      transform: translateX(4%);
    }
    .modal-body{
      padding: 0rem;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    }
  
    .modal-content {
        display: flex;
        width: 343px;
        padding: 80px 16px 16px 16px;
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
        background-color: #222223;
        width: 400px;
        padding: 16px;
        padding-bottom: 0px;
        padding-top: 40px !important;
        border-radius: 8px;
        text-align: center;
        gap: 0px !important;
    }
    
  
    .modal-header img {
       
        position: absolute;
        top: -55px;
        left: 50%;
        transform: translateX(-50%);

    }

    .modal-header {
        border-bottom: 0px !important;
    }

    .modal-footer {
        border-top: 0px !important;
        min-width: 100%;
    }

    .done_button {
        width: 100%;
        color: var(--Dark-Purple, #251740);
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;

        display: flex;
        padding: 14px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        background-image: url('../../../assets/images/etnex_home/colourful_bg.png');
    }
    .input-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        width: 100%;
        padding: 1rem;
    }
    .input-label {
        margin-bottom: 4px; 
        color: white; 
    }

    .hash-input {
        width: 100%;
        padding: 10px 20px; 
        border: 1px solid var(--Neon-Blue, #0BDDCC);
        background-color: transparent;
        color: white;
        border-radius: 5px;
        outline: none;
        box-sizing: border-box;
        font-size: 16px;
    }

    .close-btn {
      position: absolute;
      color: #fff;
      top: 3px;
      right: 16px;
      cursor: pointer;
      font-size: 24px;
    }
</style>